import axios from 'axios';
import {
    FETCH_DATA,
    FETCH_OFFER,
    SET_IS_MODAL_OPEN,
    SET_STATUS,
} from '../constants/index';

export const fetchData = () => async (dispatch) => axios.get('/api/compact-auto')
    .then((res) => dispatch({
        type: FETCH_DATA,
        payload: res.data,
    }))
    .catch(() => {});

export const fetchOffer = () => async (dispatch) => axios.get('/text/life-rb-adult/oferta/1')
    .then((res) => dispatch({
        type: FETCH_OFFER,
        payload: res.data,
    }))
    .catch(() => {});

export const setModalIsOpen = (state, contentId = null) => ({
    type: SET_IS_MODAL_OPEN,
    payload: {
        isModalOpen: state,
        modalContentId: contentId,
    },
});

export const getStatus = () => async (dispatch) => axios.get('/api/status')
    .then((res) => {
        if (res.status === 200 && res.data) {
            dispatch({
                type: SET_STATUS,
                payload: res.data,
            });
        }
    })
    .catch(() => {});
