import { useDispatch } from 'react-redux';
import { setModalIsOpen } from '../actions/actionsContent';

export default () => {
    const dispatch = useDispatch();

    const openModalHandle = (contentId) => () => {
        dispatch(setModalIsOpen(true, contentId));
    };

    return { openModalHandle };
};
