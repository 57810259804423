import React, { useEffect } from 'react';
// import axios from 'axios';
import { useDispatch } from 'react-redux';
// import { PrivacyPolicy } from '@b3w/pp-portal';
import '@b3w/pp-portal/dist/portal-bundle.css';
import { Statistic } from '@b3w/react-statistic';
// import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Routes from '../Routes/Routes';
import { fetchData, getStatus } from '../../actions/actionsContent';
import './App.sass';
// import AddToHomeScreen from '../AddToHomeScreen/AddToHomeScreen';

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchData());
        dispatch(getStatus());
    }, []);

    return (
        <>
            <Statistic
                path="/referer/statistic"
                timeoutMinutes={1}
                from
            />
            <Header />
            <Routes />
        </>
    );
};

export default App;
