import React, { useState } from 'react';
import { cn } from '@bem-react/classname';
import Lazyload from 'react-lazyload';
import { useSelector, useDispatch } from 'react-redux';
// import SVG from 'react-inlinesvg';
import useModalHandle from '../../hooks/useModalHandle';
// import videoIcon from './images/video.svg';

import './VideoList.sass';

const VideoList = () => {
    const cnVideoList = cn('VideoList');
    const dispatch = useDispatch();
    const persons = useSelector((state) => state.persons);
    const { openModalHandle } = useModalHandle();
    const [pageLimit, setPageLimit] = useState(8);

    const handleButtonClick = (image) => () => {
        dispatch(openModalHandle(image));
    };

    const handleMoreButtun = () => {
        const top = window.scrollY;
        setPageLimit((state) => state + 8);
        setImmediate(() => {
            window.scrollTo(0, top);
        });
    };

    const renderItem = (image, id, title) => (
        <React.Fragment key={id}>
            <div
                className={cnVideoList('Item')}
            >
                <button
                    onClick={handleButtonClick(image)}
                    className={cnVideoList('Button')}
                    type="button"
                >
                    <Lazyload
                        once
                        placeholder={<div className={cnVideoList('Loader')} />}
                        classNamePrefix={cnVideoList('Lazyload')}
                    >
                        <div
                            className={cnVideoList('Image')}
                            style={{ backgroundImage: `url(${image})` }}
                        />
                        <div
                            className={cnVideoList('Divider')}
                        />
                    </Lazyload>
                    <span className={cnVideoList('Title')}>{title}</span>
                </button>
            </div>
        </React.Fragment>
    );

    return (
        <>
            <div className={cnVideoList('List')}>
                {persons.filter((item, index) => index < pageLimit)
                    .map((item, index) => (
                        renderItem(`/storage/free/${item.previews?.[0]?.link}`, item.id, item.name, index)
                    ))}
            </div>
            {persons.length >= pageLimit ? (
                <div className={cnVideoList('ButtonWrapper')}>
                    <button
                        className={cnVideoList('More')}
                        onClick={handleMoreButtun}
                        type="button"
                    >
                        Ещё
                    </button>
                </div>
            ) : null}
        </>
    );
};

export default VideoList;
