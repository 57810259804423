/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import { render } from 'react-dom';
import 'normalize.css';
import './components/common/styles/main.sass';
import Root from './root';
import { portalsInit } from './utils/portals';
import { portalsSelectors } from './constants/portals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

portalsInit(portalsSelectors);

const mounthNode = document.getElementById('root');
render(<Root />, mounthNode);

// PWA
serviceWorkerRegistration.register();
